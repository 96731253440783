//This file contains the table customizations (shared by finance and CA) as well as the column formatters (except actions formatter cus that needs a lot of state things)
import {
    Popup,
    Icon,
    Table,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    Placeholder, PlaceholderLine, TableFooter
} from "semantic-ui-react";
import React from "react";

export const getFormattedDate =(date)=> {
    if (Date.parse(date)) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');

        return month + '-' + day + '-' + year;
    } else {
        return ''
    }
}
export const downloadCSV=(array, columns, titlePrefix ='')=> {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array, columns);
    if (csv == null) return;
    const today = new Date();
    const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };
    const filename =  titlePrefix + 'Invoices - ' + today.toLocaleDateString('en-US', options).replace(/,/g, "");
    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
    }
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
}
export const convertArrayOfObjectsToCSV=(array, columns)=> {
    let result;
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const titles = columns.filter(x => x.csvExport).map(({name}) => [name]).flat();
    const keys = columns.filter(x => x.csvExport).map(({id}) => [id]).flat();
    result = '';
    result += titles.join(columnDelimiter);
    result += lineDelimiter;
    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;
            result += item[key];
            ctr++;
        });
        result += lineDelimiter;
    });
    return result;
}

//Formatter for date archived col header
export const archiveFormatter = (column, colIndex, { sortElement, filterElement }) => {
    return (
        <div className="col-header-container">
            <span className="col-header-text">{column.text} </span>
            <span>{sortElement} </span>
            <span className="col-header-tooltip-container">
                <Popup position='top center' content='After archival, files will be held for 30 days then purged. You can still download archived files before they are purged.' trigger={<Icon name='question'/>}/>
            </span>
        </div>

    )
}

//Formatter for date downloaded col header
export const downloadFormatter = (column, colIndex, { sortElement, filterElement }) => {
    return (
        <div className="col-header-container">
            <span className="col-header-text">{column.text} </span>
            <span>{sortElement} </span>
            <span className="col-header-tooltip-container">
                <Popup position='top center' content='Click on a row to see downloads.' trigger={<Icon name='question'/>}/>
            </span>
        </div>

    )
}

export const skeletonTable = (rows = 5, columns) => {
    return (
        <Table celled striped unstackable padded>
            <TableHeader>
                <TableRow>
                    {columns.filter(x=> !x.omit).map((col) => {
                        return (
                            <TableHeaderCell width={8} id={col.name.replace(/ /g, "-")+'-header'}>
                                {col.name}
                                {
                                    col.name === 'Date Downloaded'&&
                                    <Popup content='Click on a row to see downloads.' trigger={<Icon color='blue' size='small' name='question circle'/>}/>
                                }
                                {
                                    col.name === 'Date Archived'&&
                                    <Popup content='After archival, files will be held for 30 days then purged. You can still download archived files before they are purged.' trigger={<Icon color='blue' size='small' name='question circle'/>}/>
                                }
                            </TableHeaderCell>
                        )
                    })}
                </TableRow>
            </TableHeader>
            <TableBody>
                {Array(rows).fill({}).map(() => (
                    <TableRow>
                        {Array(columns.filter(x=> !x.omit).length).fill({})
                            .map(() => (
                                <TableCell>
                                    <Placeholder>
                                        <PlaceholderLine/>
                                    </Placeholder>
                                </TableCell>
                            ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export const emptyTable = (columns) => {
    return (
        <Table celled striped unstackable padded>
            <TableHeader>
                <TableRow>
                    {columns.filter(x=> !x.omit).map((col) => {
                        return (
                            <TableHeaderCell width={8} id={col.name.replace(/ /g, "-")+'-header'}>
                                {col.name}
                                {
                                    col.name === 'Date Downloaded'&&
                                    <Popup content='Click on a row to see downloads.' trigger={<Icon color='blue' size='small' name='question circle'/>}/>
                                }
                                {
                                    col.name === 'Date Archived'&&
                                    <Popup content='After archival, files will be held for 30 days then purged. You can still download archived files before they are purged.' trigger={<Icon color='blue' size='small' name='question circle'/>}/>
                                }
                            </TableHeaderCell>
                        )
                    })}
                </TableRow>
            </TableHeader>
            <TableFooter fullWidth>
                <TableRow textAlign='center'>
                    <TableCell colSpan='16'>
                        The requested table is empty. Try different filters settings to get your files
                    </TableCell>
                </TableRow>
            </TableFooter>
        </Table>
    )
}

export const datetimeFormatter = (cell) => {
    if (cell) {
        // Remove the "[UTC]" from the date string
        const date_str = cell.toString().split("[")[0]

        // Create a date object from the date string
        const date_obj = new Date(date_str)
        return (
            <span>
                {date_obj.toLocaleString()}
            </span>
        )
    } else{
        return '—'
    }
}
