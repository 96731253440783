import React from 'react'
import { Component } from 'react'

const ROLES = {
  CA: 'claimadmin-invoice-user',
  FINANCE: 'imr-invoice-user',
  INVALID: 'invalid'
}

const UserContext = React.createContext({
  userData: {
    user: '',
    ca: '',
    permissions: {},
    caOptions: {}
  },
  setUserData: () => { }
})

class UserProvider extends Component {
  setUserData = data => {
    this.setState({ userData: data })
  }

  state = {
    userData: {
      user: '',
      ca: '',
      permissions: {},
      caOptions: {},
    },
    setUserData: this.setUserData
  }

  render() {
    const { children } = this.props

    return (
      <UserContext.Provider
        value={this.state}
      >
        {children}
      </UserContext.Provider>
    )
  }
}

export default UserContext

export { UserProvider, ROLES }