import axios from 'axios'
import jwt_decode from 'jwt-decode'
import {toast} from 'react-toastify'

let instance
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    instance = axios.create({
        baseURL: 'https://ce-dxcportal-cadir-uat.maximus.com/invoices/api'
        //baseURL: 'http://localhost:8080/invoices/api' //<-- use this if testing with local backend (scip1 or scip-mock-server)
    })
} else {
    // production code
    instance = axios.create({
        baseURL: '/invoices/api'
    })
}

if (!localStorage.getItem('auth') || (localStorage.getItem('auth') && !JSON.parse(localStorage.getItem('auth')).token)){
    window.location.assign(window.location.protocol + "//" + window.location.hostname + "/dxc/#/")
}

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
instance.defaults.withCredentials = true;
instance.defaults.xsrfCookieName = "XSRF-TOKEN";
instance.defaults.xsrfHeaderName = "X-XSRF-TOKEN";

// Set JWT for all requests
instance.interceptors.request.use(config => {
    if (localStorage.getItem('auth')) {
        const token = JSON.parse(localStorage.getItem('auth')).token
        const cookie = getCookie('XSRF-TOKEN')
        config.headers.Authorization = token ? `Bearer ${token}` : ''
        instance.defaults.headers.common['X-CSRF-TOKEN'] = token ? token : ''
        instance.defaults.headers.common['X-XSRF-TOKEN'] = cookie ? cookie : ''
    }
    return config
}, error => {
    return Promise.reject(error);
})

// Handle errors for all requests
instance.interceptors.response.use(resp => {
    return resp
}, error => {
    //If token expired, redirect to login again
    const token = JSON.parse(localStorage.getItem('auth')).token
    if (!token){
        window.location.assign(window.location.protocol + "//" + window.location.hostname + "/dxc/#/")
    }
    const { exp } = jwt_decode(token)
    const expirationTime = exp * 1000 //Convert to ms to compare with current Date.now
    if (Date.now() >= expirationTime) {
        window.location.assign(window.location.protocol + "//" + window.location.hostname + "/dxc/#/")
    }
    //Else if network error, check internet connection
    else if (!error.response) {
        toast.error(<p><strong>Network error.</strong> Check your internet connection.</p>)
    }
    //Else if unauthorized, try logging in as diff user
    else if (error.response.status === 401 || error.response.status === 403) {
        toast.error(<p><strong>Unauthorized action.</strong> Try logging in as a different user.</p>)
    }
    //Else if server error, try again or contact someone
    else if (error.response.status === 500 || error.response.status === 503) {
        toast.error(<p><strong>Server error.</strong> Try again or contact a technician.</p>)
    }
    //For all other errors, try again or contact someone
    else {
        toast.error(<p><strong>Action failed.</strong> Try again or contact a technician.</p>)
    }

    return Promise.reject(error);
})

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export default instance
