import { Component } from 'react'
import {Button, Header} from 'semantic-ui-react'
import './AuthFailed.css'
import DXCNavbar from './DXCNavbar'
import DXCFooter from './DXCFooter'
import UserContext from './UserContext'

export default class AuthFailed extends Component {
    static contextType = UserContext

    render() {
        return (
            <div className='main-wrapper'>
                <DXCNavbar />
                <div className='error-wrapper' >
                    <div className='error-row'>
                        <div className='error-column'>
                            <div className='error-text'>
                            <Header as='h1' size='large'>You are not authorized to use this website. </Header>
                            <Header as='h2' size='medium'>Try logging in as a different user or contact an administrator.</Header>
                            </div>
                            <Button primary style={{ width: '15rem' }} onClick={() =>
                                window.location.assign("/dxc/#/")} >Switch Users</Button>
                        </div>
                    </div>
                </div>
                <DXCFooter />
            </div>
        )
    }
}
