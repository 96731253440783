import React, {Component} from 'react'

import './Filters.css'
import UserContext from './UserContext'
import {Dropdown, FormField, Button, Form, FormGroup} from "semantic-ui-react";
import {DateInput} from 'semantic-ui-calendar-react';

//Format needed for react-bootstrap-table2 filters
export const multiSelectOptions = {
    "Unopened": 'Unopened',
    "Downloaded": 'Downloaded',
    "Archived": 'Archived',
    "Deleted": 'Deleted'
}

//Format need for react-select options
export const statusOptions = [
    {value: 'Unopened', text: 'Unopened'},
    {value: 'Downloaded', text: 'Downloaded'},
    {value: 'Archived', text: 'Archived'},
    {value: 'Deleted', text: 'Deleted'}
]

export const dateOptions = [
    {value: '<=', text: 'Before'},
    {value: '>=', text: 'After'},
    {value: '=', text: 'On'}
]

/*Used to conditionally move filters into a collapsible panel on small screens*/
export const ConditionalWrapper = ({condition, wrapper, children}) =>
    condition ? wrapper(children) : children;


export default class Filters extends Component {
    static contextType = UserContext

    render() {
        const inputCA = this.props.inputCA
        const inputStatus = this.props.inputStatus
        const inputComp = this.props.inputComp
        const inputDate = this.props.inputDate
        const caPage = this.props.caPage

        return (
            <Form className='w-100' widths='equal'>
                <FormGroup className='mb-0'>
                    {!caPage &&
                        <FormField width={this.props.mobile ? 16 : this.props.tablet ? 8 : 6}>
                            <label htmlFor="ca-filter"><strong>Claim Administrator</strong></label>
                            <Dropdown id='ca-filter'
                                      className="ca-filter"
                                      options={this.context.userData.caOptions}
                                      placeholder='Select Claims Admin...'
                                      value={inputCA}
                                      clearable selection search closeOnChange closeOnBlur closeOnEscape
                                      aria-label="ca-filter"
                                      onChange={this.props.handleCAClick}
                                      icon='search'
                            />
                        </FormField>
                    }
                    <FormField width={this.props.mobile ? 16 : this.props.tablet ? 8 : 6}
                               className={this.props.mobile ? 'my-2' : ''}>
                        <label htmlFor="statusfilter"><strong>Status</strong></label>
                        <Dropdown id="statusfilter"
                                  className="status-filter"
                                  options={caPage ? statusOptions.slice(0, -1) : statusOptions}
                                  placeholder='Select Status...'
                                  value={inputStatus}
                                  aria-label="status-filter"
                                  multiple selection fluid clearable
                                  onChange={this.props.handleStatusClick}
                        />
                    </FormField>
                </FormGroup>
                <FormGroup unstackable className={!this.props.mobile&& 'mt-2 '}>
                    <FormField width={this.props.mobile ? 5 : 4}>
                        <label htmlFor="datefilter"><strong>Date Uploaded</strong></label>
                        <Dropdown id="datefilter"
                                  className="date-comp-filter"
                                  options={dateOptions}
                                  value={inputComp}
                                  aria-label="date-filter"
                                  onChange={this.props.handleTimeCompClick}
                                  placeholder=""
                                  selection
                        />
                    </FormField>
                    <FormField className={!this.props.mobile&&'mt-4'} width={this.props.mobile ? 6 : 5}>
                        <DateInput name="selectDate"
                                   onChange={this.props.handleTimeClick}
                                   value={inputDate}
                                   id='datepicker'
                                   dateFormat='MM-DD-YYYY'
                                   closable
                                   markColor='violet'
                                   popupPosition='right center'
                        />
                    </FormField>
                    <FormField className={!this.props.mobile?'mt-4 flex-end':" flex-end"} width={this.props.tablet?4:3}>
                        <Button className='resetFilter' basic color='red' onClick={this.props.resetFilters}>Clear Filters </Button>
                    </FormField>
                </FormGroup>

            </Form>
        )
    }
}
