import { Component } from 'react'
import { Redirect } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import axios from 'axios'

import UserContext, { ROLES } from './UserContext'
import LoginSpinner from './LoginSpinner'

export default class Login extends Component {
    static contextType = UserContext
    constructor() {
        super();

        this.state = {
            caOptions: []
        }
    }

    //Fetch and store mapping of all CA realmIds(short names) and realmTitles(pretty names)
    getCaOptions = async () => {
        const options = []
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // dev code- using dummy data instead of actual realm list endpoint to prevent CORS issues in dev
            console.log('this is a dev build')
            const caOptions = [
                { value: 'cyberdyne', text: 'CyberDyne' },
                { value: 'alaska', text: 'ALASKA NATIONAL INSURANCE' },
                { value: 'esis', text: 'ACE USA / ESIS' },
                { value: 'aims', text: 'Acclamation Insurance Management Services'},
                { value: 'acm', text: 'American Claims Management'}
            ]
            this.setState({caOptions : caOptions})
        } else {
            // production code
            await axios.get(window.location.protocol + "//" + window.location.hostname + "/dxc/public/api/realm_list/org").then(res => {
                res.data.forEach(ca => {
                    options.push({ value: ca.realmId, text: ca.realmTitle })
                })
            })
            this.setState({caOptions : options})
        }
    }

    async componentDidMount() {
        if (window.location.hash || localStorage.getItem('auth')) {

            if (window.location.hash) {
                // Get jwt from window location hash, decode it, and store it in session storage
                const token = window.location.hash.split('&').filter(i => i.startsWith('access_token')).pop().split('=').pop()
                const decodedJWT = jwt_decode(token)
                const value = {
                    "accessToken": token,
                    "username": decodedJWT.email,
                }
                localStorage.setItem('auth', JSON.stringify(value));
            }

            //Get jwt from session storage and decode it
            const token = JSON.parse(localStorage.getItem('auth')).token
            const decodedJWT = jwt_decode(token)

            //If jwt is expired, redirect user to log in again
            const { exp } = decodedJWT
            const expirationTime = exp * 1000 //Convert to ms to compare with current Date.now
            if (Date.now() >= expirationTime) {
                await axios.post(window.location.protocol + "//" + window.location.hostname + "/dxc/api/users/logout").then(res => {
                    console.log('logout response', res)
                })
                //window.location.assign(window.location.protocol + "//" + window.location.hostname + "/dxc/#/ext_logout")
            }

            //Determine the user's role (claim admin or max finance member)
            let role = ROLES.INVALID
            for (let i = 0; i < decodedJWT.realm_access.roles.length; i++) {
                switch (decodedJWT.realm_access.roles[i]) {
                    case ROLES.CA:
                        role = ROLES.CA
                        break
                    case ROLES.FINANCE:
                        role = ROLES.FINANCE
                        break
                    default:
                        //Do nothing
                }

                if (role === ROLES.CA || role === ROLES.FINANCE) {
                    break
                }
            }

            await this.getCaOptions()

            //If CA user, figure out the pretty CA name to store in user context
            let prettyCa = ''
            if (role === ROLES.CA) {
                const realm = decodedJWT.iss.split('/').pop()
                const ca = this.state.caOptions.filter((entry) => entry.value === realm)
                prettyCa = ca.length > 0 ? ca[0].text: ''
            }

            //Store userdata in user context
            let roles = decodedJWT.resource_access.dxc.roles;
            this.context.setUserData({
                user: decodedJWT.email,
                ca: prettyCa, //prettyCa will be empty string for finance users
                permissions: {
                    //Invoice related permissions
                    // !! converts value to boolean
                    "view": !!roles.includes("invoices.view"),
                    "download": !!roles.includes("invoices.download"),
                    "upload": !!roles.includes("invoices.upload"),
                    "archive": !!roles.includes("invoices.archive"),
                    "delete": !!roles.includes("invoices.delete"),

                    //Navbar related permissions
                    "admin":  !!roles.includes("participant.admin"),
                    "caseView": !!roles.includes("case.view"),
                    "caseAppend": !!roles.includes("case.append"),
                },
                role: role,
                caOptions: this.state.caOptions,
            })
        }
    }

    render() {
        // Redirect user to the appropriate site according to their role
        if (window.location.hash || localStorage.getItem('auth')) {
            if (this.context.userData.role) {
                switch (this.context.userData.role) {
                    case ROLES.CA:
                        return (<Redirect to={{ pathname: `/ca/loggedin` }} />)
                    case ROLES.FINANCE:
                        return (<Redirect to={{ pathname: `/finance/loggedin` }} />)
                    default: //Redirect to error page for someone with jwt but wrong role
                        return (<Redirect to={{ pathname: "/error" }} />)
                }
            }
            else {
                return (<LoginSpinner/>)
            }
        }
        else {
            //Redirect to dxc realm select page (link will not work with localhost)
            window.location.assign("/dxc/#/")
        }
    }
}
