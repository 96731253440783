import React from 'react'
import {Loader} from "semantic-ui-react";

export default class LoginSpinner extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            timeoutTimerMilliseconds: 600000, //10 min
        }
        this.initialTimer = null
    }

    componentDidMount = () => {
        this.setState({
            visible: true,
            content: <Loader active inline='centered' className="violet m-a" size='massive'/>
        })
        this.timeoutTimer = setTimeout(() => {
            this.setState({
                visible: true,
                content: <div style={{position: 'fixed', top: '20%', left: '50%', transform: 'translate(-50%, -50%)'}}><h1>Page timed out.</h1> <h2>Check your internet connection.</h2></div>,
        })}, this.state.timeoutTimerMilliseconds)
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutTimer)
    }

    render() {
        return (this.state.visible) ? this.state.content : null
    }
}
