import 'core-js/stable'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'

import { UserProvider } from './UserContext'

import '@ird/catapult/semantic.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'

import Routes from "./Routes"

ReactDOM.render(
  <UserProvider >
      <Routes />
  </UserProvider>,
  document.getElementById('root')
)
