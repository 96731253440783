import {Component} from 'react'
import {Menu, MenuItem, MenuMenu, Dropdown, DropdownMenu, Icon, Header, Divider, Popup,} from 'semantic-ui-react'

import UserContext from './UserContext'
import './DXCNavbar.css'
import axios from "axios";

export default class DXCNavbar extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.state = {
            user: '',
            permissions: '',
            isMobile: false
        }
    }

    componentDidMount() {
        this.setState({user: this.context.userData.user})
        this.setState({permissions: this.context.userData.permissions})
        this.setState({isMobile: window.innerWidth < 850})
        window.addEventListener('resize', this.onResize);
    }

    onResize = ()=> {
        this.setState({isMobile: window.innerWidth < 850})
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    logout() {
        axios.post(window.location.protocol + "//" + window.location.hostname + "/dxc/api/users/logout").then(res => {
            console.log('logout response', res)
            window.location.assign(window.location.protocol + "//" + window.location.hostname + "/dxc/#/")
        })
        //window.location.assign(window.location.protocol + '//' + window.location.hostname + '/dxc/#/ext_logout')
    }

    render() {
        return (
            <Menu className="inverted purple navbar">
                <div className="flex spaced w-100 px-2">
                    <div className="flex">
                        <MenuItem>
                            <a href="/dxc/#/" className='align-items-center'>
                                <img className="dxc_icon" src={require('./assets/dxc_icon-06.png').default}
                                     alt="DXC Icon"/>
                            {
                                this.state.isMobile ?
                                    <strong>DXC</strong> :
                                    <strong>Document Exchange - CA DWC IMR</strong>
                            }
                            </a>
                        </MenuItem>
                    </div>
                        {
                            !this.state.isMobile &&
                            <MenuMenu position="right" className="vertical-center">
                                {this.state.permissions.caseView &&
                                    <Dropdown item text="Cases">
                                        <DropdownMenu>
                                            <MenuItem className="not inverted">
                                                <a href='/dxc/#/cases'>Case Search</a>
                                            </MenuItem>
                                            <MenuItem className="not inverted">
                                                <a href='/dxc/#/cases/rfi'>Request For Information</a>
                                            </MenuItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                }
                                {(this.state.permissions.caseView || this.state.permissions.caseAppend) &&
                                    <Dropdown item text="Documents">
                                        <DropdownMenu>
                                            this.state.permissions.caseView &&
                                                <MenuItem className="not inverted">
                                                    <a href='/dxc/#/events'>NOARFI Requests</a>
                                                </MenuItem>
                                            }
                                            {this.state.permissions.caseAppend &&
                                                <MenuItem className="not inverted">
                                                    <a href='/dxc/#/docs'>Submit Documents</a>
                                                </MenuItem>
                                            }
                                        </DropdownMenu>
                                    </Dropdown>
                                }

                                <MenuItem className="mr-2">
                                    <a href='/invoices'>Invoices</a>
                                </MenuItem>
                                {this.state.permissions.admin &&
                                    <MenuItem>
                                        <a href="/dxc/#/admin">Administration</a>
                                    </MenuItem>
                                }

                                <span className="inverted mr-3">{this.state.user}</span>
                                <Popup content='Logout' trigger={<Icon onClick={() => this.logout()} link size="large" name="sign out"/>}/>
                            </MenuMenu>
                        }


                    {this.state.isMobile &&
                        <MenuMenu position="right" className="vertical-center">
                            <Dropdown item icon="bars" >
                                <DropdownMenu>
                                    <Header className='mini'>{this.state.user}</Header>
                                    <Divider className="m-0"/>

                                    {this.state.permissions.caseView &&
                                        <>
                                        <Header className='mini'>Cases</Header>
                                        <a href='/dxc/#/cases'>
                                            <MenuItem className="not inverted">
                                                Case Search
                                            </MenuItem>
                                        </a>
                                        <a href='/dxc/#/cases/rfi'>
                                            <MenuItem className="not inverted">
                                                Request For Information
                                            </MenuItem>
                                        </a>
                                        <Divider className="m-0"/>
                                        </>
                                    }
                                   {(this.state.permissions.caseView || this.state.permissions.caseAppend) &&
                                       <>
                                        <Header className='mini'>Documents</Header>
                                        {this.state.permissions.caseView &&
                                            <a href='/dxc/#/events'>
                                                <MenuItem className="not inverted">
                                                    NOARFI Records
                                                </MenuItem>
                                            </a>
                                        }
                                        {this.state.permissions.caseAppend &&
                                            <a href='/dxc/#/docs'>
                                                <MenuItem className="not inverted">
                                                    Submit Documents
                                                </MenuItem>
                                            </a>
                                        }
                                        <Divider className="m-0" v-if="identifiers.roles.includes('invoices.view')"/>
                                       </>
                                   }
                                    <a href='/invoices'>
                                        <MenuItem className="not inverted">
                                            Invoices
                                        </MenuItem>
                                    </a>
                                    <Divider className="m-0"/>
                                    <MenuItem className="not inverted" onClick={() => this.logout()}>
                                        Logout
                                    </MenuItem>
                                </DropdownMenu>
                            </Dropdown>
                        </MenuMenu>
                    }

                </div>

            </Menu>
        )
    }
}
