import React, { Component } from 'react'
import UserContext from './UserContext'
import jwt_decode from 'jwt-decode'
import LoginSpinner from './LoginSpinner'
import axios from "axios";

import {ModalContent, ModalHeader, Modal, ModalDescription, Progress} from "semantic-ui-react";

export default function HOC(ComposedClass) {
  return class extends Component {
    static contextType = UserContext

    constructor(props) {
      super(props);
      this.state = {
        showTimeout: false,
        showRefresh: false,
        isUploading: false,
        warningTime: 1000 * 60 * 24,  //24 minutes to warning
        signoutTime: 1000 * 60 * 25,  //25 minutes to logout
        difference: 60,
      };
    }

    componentDidMount() {
      this.events = [
        'load',
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keypress'
      ];

      for (let i in this.events) {
        if (this.events.hasOwnProperty(i)) {
          window.addEventListener(this.events[i], this.resetTimeout);
        }
      }
      this.setTimeout();
      this.difference = setInterval(() => this.state.showTimeout && this.setState({ difference: this.state.difference - 1 }), 1000);

    }

    componentWillUnmount() {
      clearInterval(this.difference);
    }

    clearTimeoutFunc = () => {
      if (this.warnTimeout) clearTimeout(this.warnTimeout);
      if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
      this.setTimeout();

      //check token for about to expire
      let ssd = localStorage.getItem('auth')
      if (ssd !== null && ssd !== 'null') {
        const token = JSON.parse(ssd).token
        const { exp } = jwt_decode(token) //in second format
        const expirationTime = (exp * 1000) //in milisecond format
        if (Date.now() >= expirationTime) { //if now > exp
          this.setState({ showRefresh: true })
          this.refreshToken()
        }
      }
    };

    setTimeout = () => {
      this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
      this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
      this.setState({ difference: 60 });
    };

    resetTimeout = () => {
      this.setState({ showTimeout: false }, this.clearTimeoutFunc())
    };

    warn = () => {
      if (this.state.isUploading) { this.resetTimeout() }
      else { this.setState({ showTimeout: true }) }
    };

    toggleShowTimeout = () => {
      this.setState({ showTimeout: !this.state.showTimeout })
    }

    handleUploading = (isUploading) => {
      this.setState({ isUploading: isUploading })
    }

    logout = () => {
      axios.post(window.location.protocol + "//" + window.location.hostname + "/dxc/api/users/logout").then(res => {
        console.log('logout response', res)
        window.location.assign(window.location.protocol + "//" + window.location.hostname + "/dxc/#/")
      })
      //window.location.assign(window.location.protocol + "//" + window.location.hostname + "/dxc/#/ext_logout")
    };

    refreshToken = () => {
      //record and clear storage
      let oldToken = JSON.parse(localStorage.getItem('auth')).token
      localStorage.clear();

      let iframe = document.createElement('iframe');
      iframe.style.display = "none";
      iframe.src = window.location.protocol + '//' + window.location.hostname + '/dxc/#/'
      iframe.id = "iframe";
      document.body.appendChild(iframe);

      //kill iframe
      let timesRun = 0;
      let obj = this;
      let interval = setInterval(() => {
        if (!document.getElementById('iframe') || timesRun === 50) {
          clearInterval(interval);
          let ssd = localStorage.getItem('auth')
          if (!ssd || ssd === null || ssd === 'null' || oldToken === JSON.parse(ssd).token) {
            window.location.assign(window.location.protocol + '//' + window.location.hostname + '/dxc/#/')
          }
        }
        else if (localStorage.getItem('auth') !== null && localStorage.getItem('auth') !== "null") {
          obj.setState({ showRefresh: false }, () => { console.log('token refresh complete') })
          var frame = document.getElementById("iframe");
          frame.parentNode.removeChild(frame);
        }
        else { timesRun += 1; }
      }, 1000); //run every second, stop at 50 sec or if success and thus iframe gone
    }

    render() {

      return (
        <div>
          <ComposedClass {...this.props} handleUploading={this.handleUploading} />
          <Modal
              open={this.state.showTimeout}
              onClose={() => this.toggleShowTimeout()}
              role='main'
              aria-labelledby='modal-timeout'
              closeIcon
              title='Timeout Modal'
          >
            <ModalHeader id='modal-timeout'>Your session is about to expire. Click anywhere to continue.</ModalHeader>
            <ModalContent>
              <ModalDescription className='py-2'>
                <Progress active value={this.state.difference} total={60} color='violet'
                          label={this.state.difference > 30 ?
                              `You will be logged out in ${this.state.difference} seconds`
                              : this.state.difference > 10 ? `${this.state.difference} seconds`
                                  : `${this.state.difference}`}/>
              </ModalDescription>
            </ModalContent>
          </Modal>

          <Modal
              open={this.state.showRefresh}
              role='main'
              aria-labelledby='modal-refresh'
              title='Refresh Modal'
              id='refreshModal'
          >
            <ModalContent>
              <ModalDescription>
                <LoginSpinner/>
              </ModalDescription>
            </ModalContent>
          </Modal>
        </div>

      );
    }
  }
}
