import { Component } from 'react'
import {Button, Input, ModalActions, ModalContent, ModalHeader, Popup} from "semantic-ui-react";
import axios from './axiosConfig'

export default class ArchiveModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            inputFile: '',
            buttonDisabled: true,
            fileName: this.props.selectedFile.fileName.substring(0, this.props.selectedFile.fileName.length - 4),
        }
    }

    checkConfirmModal = () => {
        if (this.state.inputFile === this.state.fileName) {
            this.setState({ buttonDisabled: false })
        }
        else {
            this.setState({ buttonDisabled: true })
        }
    }

    handleArchiveConfirm = () => {
        const formData = new FormData()
        formData.append('base64SortKey', btoa(this.props.selectedFile.sortKey))
        if(!this.props.caPage) {
            formData.append('base64ClaimAdmin', btoa(this.props.selectedFile.ca))
        }

        axios.put('/invoice/archive', formData)
             .then(() => { this.props.refreshTable() })

        this.props.toggleShowArchive()  //close archive modal
    }

    componentDidMount() {
        if (document.querySelectorAll('[role="dialog"]')[0]) {
            document.querySelectorAll('[role="dialog"]')[0].setAttribute('title', 'Archive Confirmation Dialog')
        }
        if (document.getElementsByClassName('page-content')[0]) {
            document.getElementsByClassName('page-content')[0].setAttribute('role', '')
        }

    }

    render() {
        return (
            <>
                    <ModalHeader id='modal-archive-confirm'>Confirm Archive</ModalHeader>
                    <ModalContent>
                        <p>
                            This action <strong>CANNOT</strong> be undone.
                            Archiving will <strong>permanently delete</strong> {this.state.fileName} after 30 days.
                            You may still download archived files prior to their purge date.
                        </p>
                        <p>
                            Confirm by typing <strong>{this.state.fileName}</strong> below:
                        </p>
                        <Input fluid type="text" placeholder={this.state.fileName} onKeyUp={e => this.setState({ inputFile: e.target.value}, this.checkConfirmModal)} ></Input>
                    </ModalContent>
                    <ModalActions>
                        <Popup disabled={!this.state.buttonDisabled} content={this.state.buttonDisabled && 'You must type the filename before confirming archive'} trigger={<span><Button onClick={this.handleArchiveConfirm} tabIndex="-1" color='green' disabled={this.state.buttonDisabled}>
                            Confirm
                        </Button></span>}/>
                        <Button className='ml-2' onClick={this.props.toggleShowArchive}>Cancel</Button>
                    </ModalActions>
            </>
        )
    }
}
