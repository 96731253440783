import {useCallback, useState, useReducer, useEffect, useContext} from 'react'
import {useDropzone} from 'react-dropzone'
import {v4 as uuidv4} from 'uuid'
import {ToastContainer, toast} from 'react-toastify'
import axios from './axiosConfig'
import UserContext from "./UserContext"
import {
    Grid,
    GridColumn,
    Header,
    HeaderSubheader,
    Icon,
    Progress,
    Form,
    Message,
    Button,
    Popup,
    FormField, GridRow, Dropdown, Input,
    FormGroup, Container
} from "semantic-ui-react";

import './Portal.css'

const ACTIONS = {
    ADD_FILE: 'add-file',
    DELETE_FILE: 'delete-file',
    SET_FILENAME: 'set-filename',
    SET_CA: 'set-ca',
    TOGGLE_EDIT_MODE: 'toggle-edit-mode',
    TOGGLE_IS_UPLOADING: 'toggle-is-uploading',
    SET_UPLOAD_PROGRESS: 'set-upload-progress',
    MARK_FOR_DELETION: 'mark-for-deletion',
    SET_UPLOAD_FAILED: 'set-upload-failed'
}

function reducer(files, action) {
    switch (action.type) {
        case ACTIONS.ADD_FILE:
            return [...files, action.payload.fileMetadata]
        case ACTIONS.DELETE_FILE:
            return files.filter(file => file.id !== action.payload.id)
        case ACTIONS.SET_FILENAME:
            return files.map((file) => {
                if (file.id === action.payload.id) {
                    //Edit file.filename AND file.actualFile.name
                    const newFile = new File([file.actualFile], action.payload.filename, {type: file.actualFile.type})

                    //If filename is blank, trigger a form error by setting filenameValidationState
                    if (!action.payload.filename) {
                        return {
                            ...file,
                            filename: action.payload.filename,
                            actualFile: newFile,
                            filenameValidationState: "error"
                        }
                    } else {
                        return {
                            ...file,
                            filename: action.payload.filename,
                            actualFile: newFile,
                            filenameValidationState: null
                        }
                    }
                }
                return file
            })
        case ACTIONS.SET_CA:
            return files.map((file) => {
                if (file.id === action.payload.id) {
                    return {...file, ca: action.payload.ca, prettyca: action.payload.prettyca}
                }
                return file
            })
        case ACTIONS.TOGGLE_EDIT_MODE:
            return files.map((file) => {
                if (file.id === action.payload.id) {
                    return {...file, inEditMode: !file.inEditMode}
                }
                return file
            })
        case ACTIONS.TOGGLE_IS_UPLOADING:
            return files.map((file) => {
                if (file.id === action.payload.id) {
                    return {...file, isUploading: !file.isUploading}
                }
                return file
            })
        case ACTIONS.SET_UPLOAD_PROGRESS:
            return files.map((file) => {
                if (file.id === action.payload.id) {
                    return {...file, uploadPercentage: action.payload.uploadPercentage}
                }
                return file
            })
        case ACTIONS.MARK_FOR_DELETION:
            return files.map((file) => {
                if (file.id === action.payload.id) {
                    return {...file, markedForDeletion: true}
                }
                return file
            })
        case ACTIONS.SET_UPLOAD_FAILED:
            return files.map((file) => {
                if (file.id === action.payload.id) {
                    return {...file, uploadFailed: true}
                }
                return file
            })
        default:
            return files
    }
}

export default function FinanceUpload(props) {

    const [files, dispatch] = useReducer(reducer, [])
    const [allowUploadAll, setAllowUploadAll] = useState(false)
    const {userData} = useContext(UserContext)
    const permissions = userData.permissions
    const MAX_UPLOAD_SIZE = 500 * 1000000 // 500MB
    const handleUploading = props.handleUploading

    useEffect(() => {
        // If all required fields are set and no files are marked as uploadFailed or are in the process of uploading,
        //  then enable the Upload All button
        let allFieldsSet = true

        files.every(file => {
            if (!file.ca || !file.filename || file.uploadFailed || file.isUploading) {
                allFieldsSet = false
                return false
            }
            return true
        })

        if (allFieldsSet === true) {
            setAllowUploadAll(true)
        } else {
            setAllowUploadAll(false)
        }

        //If file marked for deletion, wait 5000 ms (for css fade-out to complete) and then delete it
        files.forEach(file => {
            if (file.markedForDeletion) {
                const timer = setTimeout(() => {
                    dispatch({type: ACTIONS.DELETE_FILE, payload: {id: file.id}})
                }, 5000)
                return () => clearTimeout(timer)
            }
        })
    }, [files])

    //Called whenever a user adds files
    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {

        //Concatenate newly added files to the list of the previously added files
        acceptedFiles.forEach((file) => {
            const fileMetadata = {
                id: uuidv4(),
                filename: file.name,
                ca: '',
                prettyca: '',
                status: 'Available',
                actualFile: file,
                inEditMode: false,
                isUploading: false,
                uploadPercentage: 0,
                uploadFailed: false,
                markedForDeletion: false,
                filenameValidationState: null,
            }
            dispatch({type: ACTIONS.ADD_FILE, payload: {fileMetadata: fileMetadata}})

            //Every time you add new files, disable the Upload All button
            setAllowUploadAll(false)
        })

        rejectedFiles.forEach((file) => {
            if (file.errors[0].code === "file-too-large") {
                toast.error(<p><strong>{file.file.name}</strong> exceeds max size of {MAX_UPLOAD_SIZE / 1000000}MB.</p>)
            }
        })

    }, [MAX_UPLOAD_SIZE])

    const {getRootProps, getInputProps, open, isDragReject} = useDropzone({
        noClick: true,
        noKeyboard: true,
        maxSize: MAX_UPLOAD_SIZE,
        onDrop
    })

    const handleUpload = async (file) => {
        props.handleUploading(true)

        //Set isUploading to true
        dispatch({type: ACTIONS.TOGGLE_IS_UPLOADING, payload: {id: file.id}})
        handleUploading(true)

        //Upload the file
        const formData = new FormData()
        formData.append('file', file.actualFile)

        await axios.post('/invoice?base64ClaimAdmin=' + btoa(file.ca), formData, {
            //Calculate upload percentage to show in upload progress bar
            onUploadProgress: (progressEvent) => {
                const uploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
                dispatch({
                    type: ACTIONS.SET_UPLOAD_PROGRESS,
                    payload: {id: file.id, uploadPercentage: uploadPercentage}
                })
            }
        })
            .then((resp) => {
                dispatch({type: ACTIONS.MARK_FOR_DELETION, payload: {id: file.id}})
                props.handleUploading(false)
            })
            .catch((resp) => {
                dispatch({type: ACTIONS.SET_UPLOAD_FAILED, payload: {id: file.id}})
                props.handleUploading(false)
            })
    }

    const handleUploadAll = async () => {
        for (const file of files) {
            await handleUpload(file)
        }
    }

    return (
        <Container>
            <Grid className="upload-page-body">
                {permissions.upload ?
                    <>
                        <ToastContainer
                            position="bottom-right"
                        />
                        <GridRow>
                            <div className="ui placeholder segment py-4" {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <Header className="placeholder-format" icon>
                                    <Icon name="cloud upload" size="big"/>
                                    <HeaderSubheader className="mb-1">
                                        {isDragReject && <p>File type not accepted.</p>}
                                        {!isDragReject && <p>Drag and drop files here or </p>}
                                    </HeaderSubheader>
                                </Header>
                                <div className="flex justify-content-between">
                                    <Button primary content='Browse' onClick={open}/>
                                </div>
                            </div>
                        </GridRow>
                        <GridRow>
                            {files.length > 0 &&
                                <>
                                    <GridRow className="file-list-header">
                                        <h2 className="file-list-title">Selected Files</h2>
                                        <Popup
                                            content='Make sure that all invoices are including the required information.'
                                            disabled={allowUploadAll}
                                            trigger={<span><Button disabled={!allowUploadAll} className='primary'
                                                                   onClick={() => handleUploadAll()}>Upload All</Button></span>}
                                        />
                                    </GridRow>
                                    <GridRow>
                                        {files.map((file) => {
                                            return <SelectedFile key={file.id} file={file} dispatch={dispatch}
                                                                 handleUpload={handleUpload}/>
                                        })}
                                    </GridRow>
                                </>
                            }
                        </GridRow>
                    </> :
                    <Message negative compact content='You are not authorized to upload documents.'/>
                }
            </Grid>
        </Container>
    )
}

/*-------------------------------------------------------------------------------------*/

function SelectedFile({file, dispatch, handleUpload}) {
    const previewFile = (actualFile) => {
        let blob = new Blob([actualFile], {type: 'application/pdf'})
        let url = window.URL.createObjectURL(blob)
        let tab = window.open()
        tab.location.href = url
    }

    const downloadFile = (file) => {
        let blob = new Blob([file.actualFile])

        //Create an anchor tag that links the invoice being downloaded
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file.filename
        link.click()
    }

    const openFile = (file) => {
        if (file.actualFile.type === 'application/pdf') {
            previewFile(file.actualFile)
        } else {
            downloadFile(file)
        }
    }

    return (
        <div className={file.markedForDeletion ? 'fade-out' : ''}>
            <Grid>
                <GridRow>
                    <EditableFilename file={file} dispatch={dispatch}/>
                    <CADropdown file={file} dispatch={dispatch}/>
                    <GridColumn width={3} className='align-items-center justify-end'>
                        <FormGroup>
                            <div>
                            <span className="float-right">
                                {file.actualFile.type === 'application/pdf' ? (
                                    <Popup content='View' trigger={
                                        <Button
                                            color='blue'
                                            size='small'
                                            icon
                                            onClick={() => openFile(file)}>
                                            <Icon name='eye'/>
                                        </Button>
                                    }></Popup>
                                ) : (
                                    <Popup content='Download' trigger={
                                        <Button
                                            color='blue'
                                            size='small'
                                            icon
                                            onClick={() => openFile(file)}>
                                            <Icon name='download'/>
                                        </Button>
                                    }></Popup>
                                )}
                                {!file.uploadFailed &&
                                    <Popup
                                        content={<span
                                            id="button-tooltip">{file.ca && file.filename ? 'Upload' : 'Filename and claims administrator are required for upload.'}</span>}
                                        trigger={<span><Button
                                            disabled={!file.ca || !file.filename || file.isUploading}
                                            color='blue'
                                            size='small'
                                            icon
                                            onClick={() => handleUpload(file)}>
                                            <Icon name="cloud upload"/>
                                        </Button></span>}
                                    >
                                    </Popup>
                                }
                                <Popup content='Delete' trigger={
                                    <Button
                                        color='red'
                                        size='small'
                                        icon
                                        onClick={() => dispatch({type: ACTIONS.DELETE_FILE, payload: {id: file.id}})}>
                                        <Icon name='trash'/>
                                    </Button>
                                }/>
                            </span>
                            </div>
                        </FormGroup>
                    </GridColumn>
                </GridRow>
            </Grid>
            <hr className='mt-3'/>
        </div>
    )
}

/*-------------------------------------------------------------------------------------*/

function EditableFilename({file, dispatch}) {

    return (
        <GridColumn width={6}>
                <Form state={file.filenameValidationState}>
                    <label><strong>Filename</strong></label>
                    {file.inEditMode ? (
                        <FormField>
                            <Input
                                type="text"
                                value={file.filename}
                                onChange={(e) => {
                                    dispatch({
                                        type: ACTIONS.SET_FILENAME,
                                        payload: {id: file.id, filename: e.target.value}
                                    })
                                }}
                                error={!file.filename &&'Filename is required.'}
                                action={<Popup content='Save' trigger={<Button icon onClick={() => dispatch({type: ACTIONS.TOGGLE_EDIT_MODE, payload: {id: file.id}})}><Icon name='check'/></Button>}/>}
                                labelPosition='right'
                            />
                        </FormField>

                    ) : (
                        <div className="filename">
                            <span className='mr-2'>{file.filename}</span>
                            {file.uploadFailed ?
                                (<></>) : (
                                    <Popup
                                        position='top left'
                                        content='Edit'
                                        trigger={
                                            <Icon link color='blue ' name='edit' onClick={() => dispatch({
                                                type: ACTIONS.TOGGLE_EDIT_MODE,
                                                payload: {id: file.id}
                                            })}></Icon>
                                        }
                                    ></Popup>
                                )
                            }
                        </div>
                    )}

                    {file.isUploading && !file.uploadFailed &&
                        <Progress active success percent={file.uploadPercentage}/>
                    }
                    {file.isUploading && file.uploadFailed &&
                        <Message negative className="upload-fail">
                            Upload failed.
                        </Message>
                    }
                </Form>
        </GridColumn>
    )
}

/*-------------------------------------------------------------------------------------*/

function CADropdown({file, dispatch}) {
    const {userData} = useContext(UserContext)

    return (
        <GridColumn width={7}>
            <FormField>
                <label htmlFor="ca-selector"><strong>Claim Administrator</strong></label>
                {file.isUploading &&
                    <div>
                        {file.prettyca}
                    </div>
                }
                {!file.isUploading && file.ca &&
                    <Dropdown aria-label="ca-selector"
                              selection fluid search closeOnChange closeOnBlur closeOnEscape
                              options={userData.caOptions}
                              value={file.ca}
                              placeholder={<div className="placeholder-text">Select Claim Administrator...</div>}
                              onChange={(e,data) => dispatch({
                                  type: ACTIONS.SET_CA, payload: {
                                      id: file.id, ca: data.value,
                                      prettyca: data.options.find(x => x.value === data.value).text
                                  }
                              })}
                    />

                }
                {!file.isUploading && !file.ca &&
                    <Dropdown id="ca-selector" aria-label="ca-selector"
                              selection fluid search closeOnChange closeOnBlur closeOnEscape
                              options={userData.caOptions}
                              value={file.ca}
                              placeholder={<div className="placeholder-text">Select Claims Admin...</div>}
                              onChange={(e, data) => dispatch({
                                  type: ACTIONS.SET_CA, payload: {
                                      id: file.id, ca: data.value,
                                      prettyca: data.options.find(x => x.value === data.value).text
                                  }
                              })}
                    />
                }
            </FormField>
        </GridColumn>
    )
}

