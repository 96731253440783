import { Component } from 'react'
import {TabPane, Tab, Container} from 'semantic-ui-react'

import FinanceUpload from './FinanceUpload'
import FinanceView from './FinanceView'
import DXCNavbar from './DXCNavbar'
import DXCFooter from './DXCFooter'
import './Portal.css'


export default class FinancePortal extends Component {
    render() {
        return (
            <div className='main-wrapper'>
                <DXCNavbar />
                    <div className="page-content" role='main'>
                        <Container fluid>
                            <h1 className='page-header-title-finance'>Invoices</h1>
                            <Tab panes={[
                                { menuItem: 'Upload Invoices', render: () => <TabPane className='basic'>
                                        <FinanceUpload role='main' handleUploading={this.props.handleUploading} />
                                    </TabPane> },
                                { menuItem: 'View Invoices', render: () => <TabPane className='basic'>
                                        <FinanceView />
                                    </TabPane> },
                            ]} defaultActiveIndex={0} />
                        </Container>
                    </div>
                <DXCFooter role='contentinfo' />
            </div>
        )
    }
}
