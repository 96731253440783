import { Component } from 'react'
import './AuthFailed.css'
import DXCNavbar from './DXCNavbar';
import DXCFooter from './DXCFooter';
import {Container, Header} from "semantic-ui-react";
export default class PageNotFound extends Component {
    render() {
        return (
            <div className='main-wrapper'>
                <DXCNavbar  />
                <div className='error-wrapper page-content' role='main'>
                    <Container>
                        <div className='error-column'>
                            <Header as='h1' size='large'>The page for the URL you used does not exist.</Header>
                            <Header size='medium'>Try going back or using a different URL. </Header>
                        </div>
                    </Container>

                </div>
                <DXCFooter />
            </div>
        )
    }
}
