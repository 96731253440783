import { Component } from 'react'
import { MenuItem, Menu, Container } from 'semantic-ui-react'

import './DXCFooter.css'

export default class DXCFooter extends Component {
    state = {year: new Date().getFullYear()}
    render() {
        const {year} = this.state
        return(
            <footer className='p-0'>
                <div id="footer">
                    <Menu secondary fluid className="inverted grey">
                        <Container className="vertical-center">
                            <MenuItem>&copy; <span className='mx-1'>{year}</span> Maximus. All rights reserved.</MenuItem>
                        </Container>
                    </Menu>
                </div>
            </footer>
        )
    }
}
