import React, { useContext } from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'

import FinancePortal from './FinancePortal'
import CAPortal from './CAPortal'
import PageNotFound from './PageNotFound'
import AuthFailed from './AuthFailed'
import Login from './Login'
import HOC from './AutoLogout'
import UserContext, { ROLES } from './UserContext'

const FinanceHoc = HOC(FinancePortal);
const CAHoc = HOC(CAPortal);

const Routes = () => {
    const { userData, /*setUserData*/ } = useContext(UserContext)

    return (
        <BrowserRouter basename={'/invoices'}>
            <Switch>
                <Route exact path={`/finance/loggedin`} render={(props) =>
                    userData.role === ROLES.FINANCE ?
                        <FinanceHoc {...props}/> :
                        <Login />
                } />
                <Route exact path={`/ca/loggedin`} render={(props) =>
                    userData.role === ROLES.CA ?
                        <CAHoc {...props}/> :
                        <Login />
                } />
                <Route exact path={`/`} render={(props) => <Login {...props} />} />
                <Route exact path={`/error`} component={AuthFailed} /> 
                <Route path="*" component={PageNotFound} />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes