import React, {Component} from 'react'
import {ToastContainer, toast} from 'react-toastify'
import {
    Accordion,
    AccordionContent,
    AccordionTitle,
    Icon,
    Message,
    Popup,
    Modal,
    Button,
    Container,
} from "semantic-ui-react";
import DataTable, {createTheme} from 'react-data-table-component';

import {
    archiveFormatter,
    downloadFormatter,
    datetimeFormatter,
    skeletonTable,
    emptyTable,
    getFormattedDate, downloadCSV
} from './SharedViewConsts'
import ArchiveModal from './ArchiveModal'
import DeleteModal from './DeleteModal'
import axios from './axiosConfig'
import UserContext from './UserContext'
import Filters, {statusOptions, dateOptions} from './Filters'

import './Portal.css'
import './DataTable.css'
import {createPortal} from "react-dom";
import DXCNavbar from "./DXCNavbar";
import DXCFooter from "./DXCFooter";

let timer;

export default class FinanceView extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props)

        this.state = {
            showArchive: false,
            showDelete: false,
            selectedFile: '',
            user: '',
            inputCA: '',
            inputStatus: [statusOptions[0].text], //Format need for react-select options
            defaultStatus: ['Unopened'], //Format needed for react-bootstrap-table2 filters
            inputComp: dateOptions[0].value,
            inputDate: getFormattedDate(new Date()),
            loading: true,
            invoices: [],
            unfilteredInvoices: [],
            permissions: '',
            isSmallScreen: false, //Used to conditionally render filters panel
            isTabletScreen: false,
            panelOpen: true,
            tableLoaded: true,
            clickCounter: 0, //Keep track of how often user tries to view/download within a time window
            dateArchivedCol: document.getElementById('hidden'),
            loggedInCA: '',
        }

    }

    async componentDidMount() {

        //Get user data from user context
        this.setState({user: this.context.userData.user})
        this.setState({permissions: this.context.userData.permissions})
        this.setState({loggedInCA: this.context.userData.ca})

        await this.refreshTable()
        this.resetFilters()
        // On first load, if there are no Unopened files, stop loading spinner
        let unopenedCount = 0
        this.state.invoices.forEach(invoice => {
            if (invoice.status === 'Unopened') {
                unopenedCount = unopenedCount + 1
            }
        })

        if (unopenedCount === 0) {
            this.setState({loading: false})
        }

        /*Determine screen size in order to conditionally render filters */
        this.checkScreenSize()
        window.addEventListener("resize", this.checkScreenSize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkScreenSize);
    }

    checkScreenSize = () => {
        this.setState({isSmallScreen: window.innerWidth < 675});
        this.setState({isTabletScreen: window.innerWidth >= 675 && window.innerWidth < 1200})
    }

    refreshTable = async (filter = false) => {
        await axios
            .get('/invoice/ca')
            .then(response => {
                this.setState(
                    {
                        invoices: response.data.map(row => {
                            return {
                                fileName: row.fileName,
                                ca: row.claimAdmin,
                                status: row.invoiceStatus,
                                dateUploaded: row.uploadList ? new Date(row.uploadList[0].actionTime.split('[')[0]) : '',
                                dateDownloaded: row.downloadList ? new Date(row.downloadList[0].actionTime.split('[')[0]) : '',
                                dateArchived: row.archiveList ? new Date(row.archiveList[0].actionTime.split('[')[0]) : '',
                                datePurged: row.purgeDate ? new Date(row.purgeDate.split('[')[0]) : '',
                                downloadList: row.downloadList,
                                uniqueName: row.uniqueName,
                                archiveList: row.archiveList,
                                type: row.type,
                                sortKey: row.sortKey
                            }
                        })
                    }, () => {
                        this.setState({unfilteredInvoices: this.state.invoices, tableLoaded: true}, () => {
                            if(filter) this.refilterInvoices()
                            // after data table exists, set portal destination for column header hint icon
                            this.setState({dateArchivedCol: document.querySelector('[data-column-id="dateArchived"]')})
                        })
                    }
                )
            })
            .catch((error) => {
                this.setState({tableLoaded: false})
            })
    }

    actionsFormatter = (row) => {
        return (
            <div>
                {row.status === 'Deleted' ? <div></div> :
                    <div className="actions-button-group" onClick={e => e.stopPropagation()}>

                        {this.state.permissions.download ?
                            <Popup content="Download" trigger={<Button primary size='tiny' icon className=''
                                                                       onClick={() => {
                                                                           this.setState({selectedFile: row}, () => this.downloadFile())
                                                                       }}>
                                <Icon name="download"/>
                            </Button>}/> :
                            <span className="button-width"></span>
                        }

                        {this.state.permissions.archive && row.status !== 'Archived' ?
                            <Popup
                                content={
                                    row.status === 'Downloaded' ?
                                        (<span id="button-tooltip">Archive</span>) :
                                        (<span id="archive-tooltip">Unopened invoices cannot be
                                            archived.</span>)}
                                trigger={<div
                                    className={row.status === 'Downloaded' ? 'enabled-button-container' : 'disabled-button-container'}>
                                    <Button primary size='tiny' icon className=''
                                            disabled={!(row.archiveList === null && row.status === 'Downloaded')}
                                            onClick={() => {
                                                this.setState({selectedFile: row}, () => this.toggleShowArchive())
                                            }}>
                                        <Icon name="inbox"/>
                                    </Button>
                                </div>
                                }
                            /> :
                            <span className="button-width"></span>
                        }

                    </div>
                }
            </div>
        )
    }

    viewFile = (row, user, state) => {
        axios
            .get('/invoice/view', {
                params: {
                    base64SortKey: btoa(row.sortKey),
                    base64ClaimAdmin: btoa(row.ca)
                },
                responseType: 'text',
                headers: {
                    'Content-Type': 'text/plain',
                    'Accept': '*'
                }
            })
            .then((resp) => {
                window.open(resp.data)
            })

        //Keep track of how often a user tries to  view file in 10 sec
        if (!this.state.prevSelectedFile || row.uniqueName !== this.state.prevSelectedFile) {
            //Store current file's unique name for future comparison
            this.setState({prevSelectedFile: row.uniqueName})

            //Clear click counter
            this.setState({clickCounter: 1})

            //clear timer
            if (timer !== null) {
                clearTimeout(timer)
                timer = null
            }

            //Set a new timer
            timer = setTimeout(() => {
                this.setState({clickCounter: 0})
            }, 10000)
        } else { //Prev selected file is same as current selected file
            this.setState((prevState) => ({
                clickCounter: prevState.clickCounter + 1
            }), () => {
                //Toast if someone clicks to view file more than twice
                if (this.state.clickCounter > 2) {
                    toast.info(<p>If you are having trouble viewing documents, try disabling your pop-up blocker. Note
                        that there may be a short delay when opening large files. </p>, {
                        hideProgressBar: true,
                        autoclose: 5000
                    })
                }
            })
        }
        toast.info(<p>Preparing to view {this.state.selectedFile.fileName}</p>, {
            hideProgressBar: true,
            autoclose: 5000
        })

        const newState = {...state, expanded: []}
        return newState
    }

    downloadFile = () => {
        axios
            .get('/invoice/download', {
                params: {
                    base64SortKey: btoa(this.state.selectedFile.sortKey),
                    base64ClaimAdmin: btoa(this.state.selectedFile.ca)
                },
                responseType: 'text',
                headers: {
                    'Content-Type': 'text/plain',
                    'Accept': '*'
                }
            })
            .then( (resp) => {
                //Create an anchor tag that links the invoice being downloaded
                let link = document.createElement('a')
                link.href = resp.data
                link.download = this.state.selectedFile.fileName
                link.click()

                // manually add download in frontend until status update bug fixed in backend
                let invoiceState = this.state.unfilteredInvoices
                const fakeDownloadDate = new Date().toISOString()
                const downloadedFileIndex = invoiceState.findIndex(x => x.sortKey === this.state.selectedFile.sortKey)
                invoiceState[downloadedFileIndex].status = 'Downloaded'
                invoiceState[downloadedFileIndex].dateDownloaded = fakeDownloadDate
                invoiceState[downloadedFileIndex].downloadList.unshift({
                    actionTime: fakeDownloadDate,
                    user: this.state.user
                })
                this.setState({
                    unfilteredInvoices: invoiceState
                }, ()=> this.refilterInvoices())
            })

        //Keep track of how often a user tries to  view file in 10 secs
        if (!this.state.prevSelectedFile || this.state.selectedFile.uniqueName !== this.state.prevSelectedFile) {
            //Store current file's unique name for future comparison
            this.setState({prevSelectedFile: this.state.selectedFile.uniqueName})
            this.setState({clickCounter: 1}) //Clear click counter

            if (timer !== null) { //clear timer
                clearTimeout(timer)
                timer = null
            }
            timer = setTimeout(() => { //Set a new timer
                this.setState({clickCounter: 0})
            }, 10000)
        } else { //Prev selected file is same as current selected file
            this.setState((prevState) => ({
                clickCounter: prevState.clickCounter + 1
            }), () => {
                //Toast if someone clicks to view file more than twice
                if (this.state.clickCounter > 2) {
                    toast.info(<p>If you are having trouble viewing documents, try disabling your pop-up blocker. Note
                        that there may be a short delay when opening large files. </p>, {
                        hideProgressBar: true,
                        autoclose: 5000
                    })
                }
            })
        }

        toast.info(<p>Your download of {this.state.selectedFile.fileName} is currently in
            progress. </p>, {hideProgressBar: true, autoclose: 5000})
    }

    toggleShowArchive = () => {
        this.setState({showArchive: !this.state.showArchive})
    }

    handleCAClick = (e, data) => {
        let ca = ''
        if (data && data.value) {
            //ca = data.options.find(x => x.value === data.value)
            ca = data.value
        }
        this.setState({inputCA: ca}, () => this.refilterInvoices())
    }

    handleStatusClick = (e, data) => {
        let statusChoices = []

        if (data && data.value) {
            statusChoices = data.value
        }

        this.setState({inputStatus: statusChoices}, () => this.refilterInvoices())
    }

    handleTimeCompClick = (e, data) => {
        this.setState({inputComp: data.value}, () => this.handleTimeClick({}, {value: this.state.inputDate}))
    }

    handleTimeClick = (e, data) => {
        this.setState({inputDate: data.value}, () => this.refilterInvoices())
    }
    refilterInvoices = () => {
        let filtered = this.state.unfilteredInvoices
        if (this.state.inputCA) {
            filtered = filtered.filter(x => x.ca.toLowerCase() === this.state.inputCA.toLowerCase())
        }
        if (this.state.inputStatus.length) {
            filtered = filtered.filter(x => this.state.inputStatus.includes(x.status))
        }
        if (this.state.inputComp === '<=') {
            // safari breaks with new Date('MM-DD-YYYY') needs '/'
            filtered = filtered.filter(x => new Date(x.dateUploaded).setHours(0, 0, 0, 0) < new Date(this.state.inputDate.replace(/-/g, "/")).setHours(0, 0, 0, 0));
        } else if (this.state.inputComp === '>=') {
            filtered = filtered.filter(x => new Date(x.dateUploaded).setHours(0, 0, 0, 0) > new Date(this.state.inputDate.replace(/-/g, "/")).setHours(0, 0, 0, 0));
        } else if (this.state.inputComp === '=') {
            filtered = filtered.filter(x => {
                return new Date(x.dateUploaded).setHours(0, 0, 0, 0) === new Date(this.state.inputDate.replace(/-/g, "/")).setHours(0, 0, 0, 0)
            });
        }
        this.setState({invoices: filtered})
    }

    resetFilters = () => {
        this.setState({
            invoices: this.state.unfilteredInvoices,
            inputCA: '',
            inputStatus: [statusOptions[0].text],
            inputComp: dateOptions[0].value,
            inputDate: getFormattedDate(new Date())
        }, () => {
            this.refilterInvoices()
        })
    }

    render() {
        const newColumns = [
            {
                id: 'uniqueName',
                selector: (row) => row.uniqueName,
                name: '',
                omit: true,
                csvExport: false
            },
            {
                id: 'fileName',
                selector: (row) => row.fileName,
                name: 'Filename',
                sortable: true,
                width: '25rem',
                csvExport: true,
                cell:(row) => {
                    return <span>{row.fileName}</span>
                }
            },
            {
                id: 'status',
                selector: (row) => row.status,
                name: 'Status',
                sortable: true,
                width: '9.5 rem',
                csvExport: true
            },
            {
                id: 'dateUploaded',
                selector: (row) => row.dateUploaded,
                name: 'Date Uploaded',
                sortable: true,
                format: (row) => datetimeFormatter(row.dateUploaded ? row.dateUploaded : ''),
                width: '11.5rem',
                csvExport: true
            },
            {
                id: 'dateDownloaded',
                selector: (row) => row.dateDownloaded,
                name: 'Date Downloaded',
                sortable: true,
                headerFormatter: downloadFormatter,
                format: (row) => datetimeFormatter(row.dateDownloaded ? row.dateDownloaded : ''),
                width: '11.5rem',
                csvExport: true
            },
            {
                id: 'dateArchived',
                selector: (row) => row.dateArchived,
                name: 'Date Archived',
                sortable: true,
                headerFormatter: archiveFormatter,
                format: (row) => datetimeFormatter(row.dateArchived ? row.dateArchived : ''),
                width: '11.5rem',
                csvExport: true
            },
            {
                id: 'datePurged',
                selector: (row) => row.datePurged,
                name: 'Date Purged',
                sortable: true,
                format: (row) => datetimeFormatter(row.datePurged ? row.datePurged : ''),
                width: '11.5rem',
                csvExport: true
            },
            {
                id: 'actions',
                name: 'Actions',
                width: '6rem',
                cell: (row) => this.actionsFormatter(row),
                csvExport: false,
            }
        ]

        const customStyles = {
            header: {
                style: {
                    minHeight: '56px',
                },
            },
            headCells: {
                style: {
                    fontWeight: 'bold',
                    backgroundColor: '#f9fafb',
                    padding: '.9375em .8125em',
                },
            },
        };
        createTheme('catapult', {
            text: {
                'font-family': 'Avenir Next',
                primary: '#424242',
                secondary: '#424242',
            },
            background: {
                default: '#ffffff',
            },
            divider: {
                default: '#dcdcdc',
            },
        });

        return (
            <div className='main-wrapper'>
                <DXCNavbar/>
                <div className="page-content" role='main'>
                    <Container fluid>
                        {this.state.permissions.view ?
                            this.state.tableLoaded ?
                                <div>
                                    <div>
                                        <div className="filter" id='filters'>
                                            <Container>
                                                <Accordion styled fluid>
                                                    <AccordionTitle
                                                        active={this.state.panelOpen}
                                                        index={0}
                                                        onClick={() => this.setState({panelOpen: !this.state.panelOpen})}
                                                    >
                                                        <Icon name='dropdown'/>
                                                        Filters
                                                    </AccordionTitle>
                                                    <AccordionContent active={this.state.panelOpen}>
                                                        <Filters
                                                            inputStatus={this.state.inputStatus}
                                                            inputComp={this.state.inputComp}
                                                            inputDate={this.state.inputDate}
                                                            handleStatusClick={this.handleStatusClick}
                                                            handleTimeCompClick={this.handleTimeCompClick}
                                                            handleTimeClick={this.handleTimeClick}
                                                            resetFilters={this.resetFilters}
                                                            caPage={true}
                                                            mobile={this.state.isSmallScreen}
                                                            tablet={this.state.isTabletScreen}
                                                        />
                                                    </AccordionContent>
                                                </Accordion>
                                            </Container>
                                        </div>

                                        <DataTable
                                            columns={newColumns}
                                            data={this.state.invoices}
                                            defaultSortAsc={false}
                                            defaultSortFieldId='dateDownloaded'
                                            pagination striped
                                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                            paginationComponentOptions={{
                                                'rowsPerPageText': 'Items per page',
                                            }}
                                            progressPending={!this.state.tableLoaded}
                                            progressComponent={skeletonTable(5, newColumns)}
                                            customStyles={customStyles}
                                            theme={'catapult'}
                                            sortIcon={<Icon name='triangle down'/>}
                                            actions={<>{this.state.unfilteredInvoices.length !== this.state.invoices.length &&
                                                <Button color='blue' className='mr-2'
                                                        onClick={() => downloadCSV(this.state.invoices, newColumns, 'My ')}
                                                        content='Download visible list as CSV'/>}
                                                <Button color='blue'
                                                        onClick={() => downloadCSV(this.state.unfilteredInvoices, newColumns, 'My ')}
                                                        content='Download entire list as CSV'/></>}
                                            noDataComponent={emptyTable(newColumns)}
                                        />
                                        {createPortal(
                                            <Popup trigger={<Icon color='blue' name='question circle'/>}
                                                   content='After archival, files will be held for 30 days then purged. You can still download archived files before they are purged.'
                                                   position='top right'/>,
                                            this.state.dateArchivedCol
                                        )}
                                    </div>
                                    <Modal closeIcon title='Archive Modal' role='main' open={this.state.showArchive}
                                           aria-labelledby='modal-archive-confirm'
                                           onClose={() => this.toggleShowArchive()}>
                                        <ArchiveModal aria-labelledby='modal-archive-confirm'
                                                      selectedFile={this.state.selectedFile}
                                                      toggleShowArchive={this.toggleShowArchive}
                                                      refreshTable={this.refreshTable}
                                                      user={this.state.user} caPage={true}/>
                                    </Modal>
                                    <Modal title='Delete Modal' role='main' aria-labelledby="modal-delete-confirm"
                                           open={this.state.showDelete} onClose={() => this.toggleShowDelete()}>
                                        <DeleteModal closeIcon selectedFile={this.state.selectedFile}
                                                     toggleShowDelete={this.toggleShowDelete}
                                                     refreshTable={this.refreshTable} user={this.state.user}
                                                     caPage={true}/>
                                    </Modal>
                                    <ToastContainer position="bottom-right"/>
                                </div>
                                :
                                <Message negative>
                                    <strong>Table failed to load.</strong> Make sure that you have a stable internet
                                    connection and
                                    that you are logged in as the correct user.
                                    <Popup content='Refresh table'
                                           trigger={<Icon className='ml-2' onClick={() => this.refreshTable(true)} link
                                                          color='blue'
                                                          name='refresh'/>}/>
                                </Message>
                            :
                            <Message negative>
                                You are not authorized to view this page.
                            </Message>}
                    </Container>
                </div>
                <DXCFooter role='contentinfo'/>
            </div>
        )
    }
}
